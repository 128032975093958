var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-block",class:[
    `mp--${_vm.mobilePadding}`,
    `dp--${_vm.desktopPadding}`,
    `mm--${_vm.mobileMargin}`,
    `dm--${_vm.desktopMargin}`,

    {
      'hide-on-mobile': _vm.hideMobile,
      'hide-on-desktop': _vm.hideDesktop,
      'cta-inline': _vm.ctaInline,
    },
  ]},[_vm._l((_vm.headlines),function(headline,index){return _c('Headline',{key:'headline' + index,staticClass:"text-block__headline",attrs:{"headline-type":headline.headlineType,"headline-size":headline.headlineSize,"headline-color":headline.headlineColor,"headline-accent-color":headline.headlineAccentColor,"eyebrow":headline.eyebrow,"headline":headline.headline,"lead":headline.lead,"align-headline":headline.alignHeadline,"vertical-margin":headline.verticalMargin}})}),_vm._v(" "),(_vm.text.html)?_c('div',{staticClass:"text",class:[_vm.textSize, _vm.textFont],style:([_vm.textAlignCss, _vm.textColorCss]),domProps:{"innerHTML":_vm._s(_vm.cleanHtml(_vm.text.html))}}):_vm._e(),_vm._v(" "),(!!_vm.ctaButtons.length)?_c('div',{staticClass:"text-block__buttons mm--medium dm--medium",style:(_vm.buttonAlignCss)},_vm._l((_vm.ctaButtons),function(button,i){return _c('Button',_vm._b({key:i},'Button',button,false))}),1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }