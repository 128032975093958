<template>
  <div
    class="text-block"
    :class="[
      `mp--${mobilePadding}`,
      `dp--${desktopPadding}`,
      `mm--${mobileMargin}`,
      `dm--${desktopMargin}`,

      {
        'hide-on-mobile': hideMobile,
        'hide-on-desktop': hideDesktop,
        'cta-inline': ctaInline,
      },
    ]"
  >
    <Headline
      v-for="(headline, index) in headlines"
      :key="'headline' + index"
      class="text-block__headline"
      :headline-type="headline.headlineType"
      :headline-size="headline.headlineSize"
      :headline-color="headline.headlineColor"
      :headline-accent-color="headline.headlineAccentColor"
      :eyebrow="headline.eyebrow"
      :headline="headline.headline"
      :lead="headline.lead"
      :align-headline="headline.alignHeadline"
      :vertical-margin="headline.verticalMargin"
    />
    <div
      v-if="text.html"
      :style="[textAlignCss, textColorCss]"
      class="text"
      :class="[textSize, textFont]"
      v-html="cleanHtml(text.html)"
    />
    <div
      v-if="!!ctaButtons.length"
      class="text-block__buttons mm--medium dm--medium"
      :style="buttonAlignCss"
    >
      <Button v-for="(button, i) in ctaButtons" :key="i" v-bind="button" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

export default {
  name: 'TextBlock',
  props: {
    headlines: {
      type: Array,
      default: () => [],
    },
    text: {
      type: Object,
      default: () => ({}),
    },
    textFont: {
      type: String,
      default: 'font-trade',
    },
    textColor: {
      type: String,
      default: '#000000',
    },
    textColorHex: {
      type: String,
      required: false,
      default: '',
    },
    textAlign: {
      type: String,
      default: 'center',
    },
    textSize: {
      type: String,
      default: 'p--small',
    },
    ctaButtons: {
      type: Array,
      default: () => [],
    },
    mobilePadding: {
      type: String,
      default: '',
    },
    desktopPadding: {
      type: String,
      default: '',
    },
    mobileMargin: {
      type: String,
      default: '',
    },
    desktopMargin: {
      type: String,
      default: '',
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    ctaInline: {
      type: Boolean,
      required: false,
      default: false,
    },
    buttonAlign: {
      type: String,
      default: 'left',
    },
  },
  computed: {
    ...mapGetters({
      currentCountryCode: 'frontend/currentCountryCode',
    }),
    countryCode() {
      return this.currentCountryCode.toLowerCase()
    },
    textColorCss() {
      return { color: color(this.textColor, this.textColorHex) }
    },
    textAlignCss() {
      return { textAlign: this.textAlign }
    },
    buttonAlignCss() {
      let buttonAlign = ''
      if (this.buttonAlign === 'left') buttonAlign = 'flex-start'
      else if (this.buttonAlign === 'center') buttonAlign = 'center'
      else if (this.buttonAlign === 'right') buttonAlign = 'flex-end'

      if (buttonAlign === '') {
        if (this.textAlign === 'center') buttonAlign = 'center'
        else if (this.textAlign === 'right') buttonAlign = 'flex-end'
      }
      return { '--button-align': buttonAlign }
    },
  },
  methods: {
    cleanHtml(html) {
      html = this.nl2br(html)
      html = this.localizeLinks(html)
      return html
    },
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    localizeLinks(html) {
      html = html.replace(/\/site\//ig, `/`)
      html = html.replace(/href="\//ig, `href="/${this.countryCode}/`)
      return html
    },
  },
}
</script>

<style lang="scss" scoped>
.text-block {
  .text {
    &.p--mini {
      @include p--small;
      &.font-base {
        @include p--small($font-base);
      }
      &.font-alternate {
        @include p--small($font-alternate);
      }
    }
    &.p--small {
      @include p--small;
      &.font-base {
        @include p--small($font-base);
      }
      &.font-alternate {
        @include p--small($font-alternate);
      }
    }
    &.p--medium {
      @include p--medium;
      &.font-base {
        @include p--medium($font-base);
      }
      &.font-alternate {
        @include p--medium($font-alternate);
      }
    }
    &.p--large {
      @include p--large;
      &.font-base {
        @include p--large($font-base);
      }
      &.font-alternate {
        @include p--large($font-alternate);
      }
    }
    &::v-deep {
      > br {
        display: none;
      }
      ul,
      ol {
        @include list('•');
        > br {
          display: none;
        }
      }
      p {
        margin: 1.6em 0;
      }
      p:first-of-type {
        margin-top: 0;
      }
      p:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .text-block__buttons {
    width: 100%;
    display: flex;
    justify-content: var(--button-align);
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .text-block {
    font-size: 1.4rem;

    &.cta-inline {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      font-size: 1.4rem;

      .text-block__headline {
        margin-top: 0;
        margin-bottom: 0;
      }

      .text {
        width: 100%;
      }

      .text-block__buttons {
        width: auto;
        margin: 0;
      }
    }
  }
}
</style>
